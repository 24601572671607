<template>
	<div class="part-pdf"
		v-click-outside="onClickOutside">
		<div class="pdf-container">
			<div class="title">
				{{ this.pdfName }}
			</div>

			<div class="pdf-display">
				<div class="pdf-view">
					<div class="viewer">
						<v-spacer />
							<vue-pdf-app
								style="height: 100vh;"
								:pdf="this.viewPDF == null ? '' : this.viewPDF">
							</vue-pdf-app>
						<v-spacer />
					</div>
				</div>

				<div class="parts-list">
					<div class="part-line">
						<h4>Parts List:</h4>
					</div>

					<v-divider 
						style="margin-bottom: 5px;"
						light/>

					<div class="part-container"
						v-for="part, index in parts"
						:key="index">
						<!-- Part ID, Part Quantity and Part Number Inputs -->
						<div class="part-line">
							<div class="search-fields"
								style="width: 80px;">
								<v-text-field
									class="centered-input"
									style="	background-color: #FFEBCD;"
									type="text"
									v-model="part.id"
									label="Item"
									hide-details="auto"
									readonly
									outlined
									dense
									@keyup.enter.native="addPartLine(index)" />
							</div>

							<div class="search-fields"
								style="width: 120px;">
								<v-text-field
									class="centered-input"
									style="	background-color: #E0FFFF;"
									type="text"
									:suffix="part.uom"
									v-model="part.quantity"
									label="Qty"
									hide-details="auto"
									readonly
									outlined
									dense/>
							</div>

							<div class="search-fields"
								style="width: 100%;">
								<v-text-field
									style="	background-color: #E0FFFF;"
									type="text"
									v-model="part.number"
									label="Part Number"
									hide-details="auto"
									readonly
									outlined
									dense
									@change="partChanged(part)"
									@keyup.enter.native="addPartLine(index)" />
							</div>
						</div>						

						<div class="part-line">
							<div class="search-fields"
								style="width: 100%;">
								<v-text-field
									style="	background-color: #E0FFFF;"
									type="text"
									v-model="part.description"
									label="Part Description"
									hide-details="auto"
									readonly
									outlined
									dense/>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import pdf from 'vue-pdf'
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import vClickOutside from 'v-click-outside'

export default {
	name: 'PartPdf',
	components: {
		pdf,
		VuePdfApp
	},
    directives: {
      clickOutside: vClickOutside.directive
    },
	props: {
		pdfID: String
	},
	data () {
		return {
			loading: false,
			pdfName: "",
			pdfFile: "",
			parts: [],
			viewPDF: "",
		}
	},
	methods: {
		getPDF() {
			if (this.pdfID == null || this.pdfID == -1) {
				return
			}
			
			this.loading = true
			var formData = new FormData();

			if (this.pdfID != -1) {
				formData.append("id", parseInt(this.pdfID))
			}

			axios.post(`${this.$config.api}/api.php?cmd=getPDF`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					this.loading = false;

					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})
					}

					return
				}

				this.pdfName = response.data.name
				this.active = response.data.active == 1 ? true : false
				this.pdfFile = response.data.file
				this.showPDFs = false
				this.parts = response.data.parts
				this.viewPDF = `${this.$config.pdf_dir}${encodeURIComponent(this.pdfFile)}`

				this.loading = false
			})
			.catch(e => {
				this.$debug.error("parts-pdfs", "getPDF", `API Error (${e})`)
				this.loading = false;
			})
		},
		onClickOutside (event) {
			this.$parent.pdfID = -1
		},
	},
	computed: {
	},
	mounted() {
		this.getPDF()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.part-pdf {
		height: 100%;
		width: 100%;
	}

	.pdf-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 100%;
		overflow: hidden;
		padding: 5px;
	}

	.pdf-display {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		height: 100%;
		width: 100%;
		overflow: hidden;
		border: 1px solid silver;
		border-radius: 5px;;

	}

	.viewer {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.parts-list {
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		min-width: 350px;
		width: 35vw;
		height: 100%;
		padding: 5px;
		border: 1px solid silver;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.part-line {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.search-fields {
		display: flex;
		padding: 5px;
		height: 100%;
		align-items: center;
	}
	
	.part-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 5px;
		padding: 2px;
		background-color: #F0FFF0;
		border: 1px solid black;
		border-radius: 5px;
	}

	.pdf-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		width: 100%;
		height: 100%;;
		margin-right: 5px;
		overflow: hidden;
	}

    .centered-input >>> input {
    	text-align: center
    }
</style>