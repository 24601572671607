import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import vClickOutside from 'v-click-outside'

fetch('./config.json')
	.then((response) => response.json())
		.then((config) => {
			var VueCookie = require('vue-cookie');
			Vue.use(vClickOutside)
			Vue.use(VueCookie);
			Vue.config.productionTip = false

			Vue.prototype.$config = config
			Vue.prototype.$api = config.api
			Vue.prototype.$dealer = config.dealer

			new Vue({
				vuetify,
				render: (h) => h(App)
			}).$mount("#app")
		})