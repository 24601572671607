<template>
	<div class="serial-search">
		<!-- Multiple Search Options Modal -->
		<div class="options-container"
			v-if="options.length > 0">
			<v-spacer />
			
			<div class="options-inner elevation-6">
				<div class="options-title">
					Partial serial matches. Please select one:

					<div class="close-button">
						<v-btn
							color="default"
							icon
							@click="options = []">
							<v-icon
								large
								color="error">
								mdi-close-circle
							</v-icon>
						</v-btn>
					</div>
				</div>

				<div class="options-results">
					<div 
						class="options-result"
						v-for="option, index in options"
						:key="index"
						@click="optionSelected(option.serial)">
						<div class="option-part">
							{{ option.serial }}
						</div>

						<div class=" option-description">
							<SerialDecoder :ser="option.serial" />
						</div>
					</div>
				</div>
			</div>

			<v-spacer />
		</div>

		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<div class="progress">
				<v-progress-circular
					:size="100"
					color="primary"
					indeterminate/>
			</div>
		</div>

		<!-- Part PDF Window -->
		<v-fade-transition 
			v-show="this.pdfID != -1">
			<div class="pdf-container"
				v-if="this.pdfID != -1">
				<div class="pdf-inner">
					<component
						:is="'PartPdf'"
						:pdfID="this.pdfID">
					</component>

					<div class="close-button-float">
						<v-btn
							icon
							@click="pdfID = -1">
							<v-icon
								large
								color="error">
								mdi-close-circle
							</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
		</v-fade-transition>

		<div class="search-container">
			<!-- Serial Search Input Section -->
			<div class="serial center">
				<div class="search center">
					<v-combobox
						class="fields"
						v-model="serial"
						:items="serials"
						ref="serialInput"
						label="Serial Number"
						hide-details="auto"
						autofocus
						outlined
						dense
						clearable
						@keyup.enter.native="getResults"/>
				</div>

				<div class="search-button center">
					<v-btn
						class="button"
						color="primary"
						:disabled="!canSearch"
						@click="getResults">
						Search
					</v-btn>
					
					<div
						style="margin-left: 10px;"
						v-if="results.length > 0">
						<v-btn
							color="success"
							@click="copyLink()">
							Copy Link
						</v-btn>
					</div>

					<div
						style="margin-left: 10px;">
						<v-btn
							color="teal"
							dark
							@click="showUpdate()">
							Help
						</v-btn>
					</div>

					<div style="margin-left: 10px;">
						<span 
							v-if="error.length > 0"
							style="font-size: smaller;color: red;"
							v-html="error">
						</span>
					</div>
				</div>

				<div class="decoder center">
					<SerialDecoder ref="decoder"/>
				</div>
			</div>

			<!-- Searial Search Results Section -->
			<div class="serial-main"
				:style="background">
				<div class="main-container"
					v-if="results.length > 0">
					<div class="notes verification"
						v-if="results.length > 0">
						<span v-if="verified">
							<span style="color: green;font-weight: 600;font-size: larger;">
								<v-icon color="green">mdi-check-bold</v-icon> This unit's configuration has been verified.
							</span><br>

							<v-divider light />

							There could still be a small chance of information missing or mistakes as the serial tool is still in testing. 

							<span style="color: red;font-weight: 600;">
								If something doesn't seem correct, please verify with the Parts Department before ordering.
							</span>
						</span>

						<span v-else>
							<span style="color: red;font-weight: 600;font-size: larger;">
								<v-icon color="red">
									mdi-file-document-alert
								</v-icon> 
							
								This unit's configuration is not verified.
							</span><br>

							<v-divider light />

							Due to how unit's configurations were stored, some units may have had their top level assemblies overwritten with newer numbers or 
							have unnoticed revisions with completely different internals. We are currently in the process of reviewing, correcting and verifying 
							all unit configurations. 
							
							<span style="color: red;font-weight: 600;">
								If something doesn't seem correct, please verify with the Parts Department before ordering.
							</span>
						</span>
					</div>

					<div class="notes"
						v-if="notes.length > 0">
						<span class="notes-title">Unit Notes:</span>

						<v-divider />

						<span class="pre-formatted">{{ this.notes }}</span>
					</div>

					<div class="top-expand">
						<div class="expand-all-button">
							<v-btn
								x-small
								color="light-blue lighten-5"
								@click="expandAll">
								Expand All
							</v-btn>
						</div>

						<div class="expand-all-button">
							<v-btn
								x-small
								color="light-blue lighten-5"
								@click="collapseAll">
								Collapse All
							</v-btn>
						</div>
					</div>
					
					<!-- Iterate and create each filtered category -->
					<div class="category elevation-3"
						v-for="category, index in filteredCategories"
						:key="index">

						<!-- Category group Line -->
						<div class="category-title"
							@click="category.visible = !category.visible">
							<div class="expand-button">
								<v-btn
									v-if="!category.visible"
									color="blue-grey lighten-4"
									x-small
									@click.stop="category.visible = true">
									<v-icon dark>mdi-plus</v-icon>
								</v-btn> 
								
								<v-btn
									v-if="category.visible"
									color="blue-grey lighten-4"
									x-small
									@click.stop="category.visible = false">
									<v-icon dark>mdi-minus</v-icon>
								</v-btn> 
							</div>

							<div class="expand-button">
								<h3> {{ category.name }}</h3>
							</div>                        
						</div>

						<!-- Display category results panel -->
						<v-expand-transition v-show="category.visible">
							<div class="results"
								v-if="category.visible">
								<!-- Display Category Header -->
								<div class="result-row elevation-6" style="background-color: #b3f0ff; border: 1px solid darkgray;">
									<div class="result-line">
										<div class="result-part centered">
											<h4>Part Number</h4>
										</div>

										<div class="result-quantity centered">
											<h4>Qty</h4>
										</div>
										
										<div class="result-description centered">
											<h4>Description</h4>
										</div>
										
										<div class="result-edited centered">
											<h4>Last Edited</h4>
										</div>
									</div>
								</div>

								<!-- Top level assemblies per category-->
								<div class="top-level elevation-6"
									v-for="item, index in category.results"
									:key="item.part + '_' + index">
									<component
										:is="'Result'"
										:item="item"
										:level="0"
										:nextLevel="1">
									</component>
								</div>
							</div>
						</v-expand-transition>
					</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
//import axios from 'axios'							// Axios is used for http async/promise api functionality.

import SerialDecoder from './serial_decoder'		// Imports the serial number decoder component displayed in top right of serial search.
import Result from './result'						// Imports result component used to display parts and bom results.
import PartPdf from './part_pdf'					// Imports Part PDF component used to a parts PDF and part list.

export default {
	name: 'SerialSearch',
	components: {
		SerialDecoder,
		Result,
		PartPdf,
	},
	props: {
	},
	data() {
		return {
			serial: "",         							// Bound to serial input.
			verified: false,
			options: [],
			serials: [],									// Holds previous serial search results
			results: [],        							// Holds current API search results.
			categories:  [],								// Sorting categories. Contains category name, category filters and category specific results
			notes: "",										// Unit specific Notes

			error: "",										// Error variable used to display errors to user.
			loading: false,									// Boolean to toggle visual loading components.

			pdfID: -1,
		}
	},
	methods: {
		getResults() {
			if (!this.canSearch) {
				return
			}

			this.results = []
			this.options = []
			this.loading = true
			this.verified = false
			this.serial = this.serial.trim().toUpperCase()
			this.notes = ""
			this.error = ""

			this.collapseAll()
			this.clear()

			axios.get(`${this.$config.api}/api.php?cmd=searchUnits&serial=${this.serial}`)
				.then(response => {
				// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = "* No results: Serial may be incorrect, incomplete or unavailable."

						this.$refs.decoder.clear()
						return
					}
					
				//API Success
					// API returned a list of possible serials
					if (response.data.serials) {
						this.options = response.data.serials
						this.loading = false
					} else {	
						// API returned a successful match
						this.clear()
						this.results = response.data.results
						this.notes = response.data.notes
						this.verified = response.data.verified
						this.loading = false
						this.$refs.decoder.decode(this.serial)
						this.sortResults()

						// Serial Search History
						if (!this.serials.includes(this.serial)){
							this.serials.unshift(this.serial)
							
							if (this.serials.length > 10){
								this.serials = this.serials.slice(0, 10)
							}
						}

						this.$refs.serialInput.blur()
					}

					console.log(response.data)
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message);
					}

					console.log(error.config);            
				})
		},
		getCategories() {
			axios.get(`${this.$config.api}/api.php?cmd=getCategories`)
			.then(response => {
				// API FAILED
				if (!response.data.success){
					this.loading = false;

					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})
					}

					return
				}

				// API Success
				console.log(response.data)
				this.categories = response.data.categories
			})
			.catch(e => {
				this.$debug.error("edit-categories", "getCategories", `API Error (${e})`)
			})
		},
		sortResults() {
			// Iterate through all results first
			for(var result of this.results){
				var matched = false     // Matched variable set when a match is made. Used to stop matching and to determine if result needs to go into other.
				// Iterate through categories to get category specific filters
				for(var category of this.categories){
					if(result.category == category.filters){
						category.results.push(result)

						matched = true
					}
				}

				// If after all itteration on that result is complete and it hasn't been matched, push it into "Other" category
				if (matched == false){
					this.categories[this.categories.length - 1].results.push(result)
				}

				// If a match was found, we need to reset the match boolean before checking the next result
				matched = false
			}
		},
		clear(){
			// Clear all categories individual results
			this.categories.forEach(function(category){
				category.results = []
			})

			// Clear the API results
			this.notes = ""
			this.verified = false
			this.results = []
		},
		expandAll() {
			for(var c in this.categories){
				this.categories[c].visible = true
			}
		},
		collapseAll(){
			for(var c in this.categories){
				this.categories[c].visible = false
			}
		},
		copyLink() {
			var link = `${this.$dealer}?serial=${this.serial}`

			navigator.clipboard.writeText(link)

			alert(`Link to unit created and copied to clipboard`)
		},
		showUpdate() {				// Opens Help Window
			this.$emit('showUpdate', true)
		},
		partClicked(id, part) {
			if (part == null || id == null) {
				return
			}

			if (part.length > 0 && id > 0){
				this.$emit("part-clicked", id, part)
			}
		},
		showPDF(pdf) {
			if (pdf == null || pdf == -1) {
				return
			}

			this.pdfID = pdf
		},
		optionSelected(serial){
			this.serial = serial
			this.getResults()
		},
		handleKey(event) {
			if (event.keyCode === 27) {
				this.pdfID = -1
			}
		},
	},
	computed: {
		canSearch() {
			if (this.serial != null && this.serial.length >= 4){
				return true
			}

			return false
		},
		filteredCategories() {
			var filtered = []

			for(var category of this.categories){
				if(category.results.length > 0){
					filtered.push(category)
				}
			}

			return filtered
		},
		background() {
			if (this.results.length == 0) {
				return ""
			}

			return "background-color: white; border: 2px solid darkgrey;"
		}
	},
	mounted: function() {
		this.getCategories()

		document.addEventListener('keyup', this.handleKey)

		let urlParams = new URLSearchParams(window.location.search)

		if(urlParams.has('serial')){
			this.serial = urlParams.get('serial')

			if(this.serial.length > 0){
				this.getResults()
			} else {
				this.serial = ''
			}
		}
	},	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.serial-search {
		height: 100%;
		width: 100%;
		font-size: 2vh;	
	}

	.search-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	.top-level {
		margin-top: 5px;
	}

	.serial-main {
		flex-grow: 1;
		display: flex;
		flex-flow: column;
		height: 100%;
		width: 100%;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.serial {
		display: flex;
		flex-flow: row;
		width: 100%;
		flex-shrink: 1;
		border: 1px solid silver;
		background-color: white;
		padding: 5px;
		margin-bottom: 5px;
	}

	.category {
		width: 100%;
		flex-shrink: 1;
		border: 1px solid silver;
		padding: 5px;
		margin-bottom: 5px;    
		background-color: whitesmoke;
	}

	.search-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		padding-left: 5px;
		padding-right: 5px;
	}

	.decoder {
		flex-shrink: 1;
	}

	.result-row {
		display:flex;
		flex-direction: column;
		width: 100%;
		min-height: 25px;
		font-weight: 600;
	}

	.result-line {
		display:flex;
		flex-direction: row;
		width: 100%;
		min-height: 25px;
		background-color: 80b3ff;
	}

	.result-part {
		width: 30%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-quantity {
		width: 5%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-description {
		width: 55%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-edited {
		width: 10%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.centered {
		text-align: center;
	}

	.notes-title {
		flex-shrink: 1;
		font-size: 2vh;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.loading {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 5;
	}

	.progress {
		position: relative;
		top: 40%;
	}

	.discontinued {
		color: red;
	}

	.category-title {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
		cursor: pointer;
		background-color: white;
	}

	.category-title:hover {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
		cursor: pointer;
		background-color: lightcyan;
	}

	.expand-button {
		display: flex;
		align-items: center;
		margin-right: 10px;
		margin-left: 5px;
	}

	.expand-all-button {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	.top-expand {
		display: flex;
		flex-direction: row;
		padding-right: 5px;
		padding-bottom: 5px;
	}

	.parts-line {
		display: flex;
		flex-direction: column;
	}

	.notes {
		border: 1px solid silver;
		border-radius: 5px;
		padding: 5px;
		background-color: blanchedalmond;
		margin-bottom: 5px;
		margin-top: 5px;
	}

	.verification {
		background-color: White;
	}

	.pre-formatted {
		display: block;
		white-space: pre-wrap;
		font-size: 0.8em;
		line-height: 0.8em;
		margin-top: 5px;
	}

	.options-container {
		display: flex;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background-color: rgba(0,0,0,.25);
		text-align: center;
		padding-top: 10vh;
	}

	.options-inner {
		display: flex;
		flex-direction: column;
		max-height: 400px;
		width: 700px;
		overflow: hidden;
		border: 1px solid black;
		border-radius: 5px;
		background-color: white;
	}

	.close-button {
		float: right;
		top:5px;
		right: 0px;
	}

	.close-button-float {
		position: absolute;
		top:12px;
		right: 15px;
	}

	.options-title {
		font-size: 18pt;
		flex-shrink: 1;
		background-color: lightblue;
		border-bottom: 1px solid black;
	}

	.options-results {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		border: 1px solid darkgrey;
		margin: 5px;
		padding: 5px;
		overflow-y: scroll;
	}

	.options-result {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid silver;
		width: 100%;
	}

	.options-result:hover {
		border: 1px solid lightgreen;
		background-color: lightcyan;
		cursor: pointer;
	}

	.option-part {
		padding: 5px;
		width: 20%;
		border-right: 1px solid silver;
		text-align: left;
	}

	.option-description {
		padding: 5px;
		width: 80%;
		text-align: left;
	}

	.pdf-container {
		display: flex;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background-color: rgba(0,0,0,.25);
		text-align: center;
		overflow: hidden;
	}

	.pdf-inner {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		margin: 10px;
		overflow: hidden;
		border: 1px solid black;
		border-radius: 5px;
		background-color: white;
	}
</style>