<template>
	<div class="documents"
		v-click-outside="onClickOutside">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<div class="progress">
				<v-progress-circular
					:size="100"
					color="primary"
					indeterminate/>
			</div>
		</div>

		<div class="document-tree">
			<span class="title">
				Select Document:
			</span>

			<v-text-field 
				v-model="search"
				label="Filter Documents"
				hide-details="auto"
				clearable
				outlined/>

			<div class="tree-container">
				<v-treeview
					:items="categories"
					:search="search"
					:open="open"
					activatable
					transition
					selection-type="independent"
					open-on-click
					return-object
					@update:active="itemActive"
					dense>
					<template v-slot:prepend="{ item, open }">
						<v-icon v-if="(item.filename == null || item.filename.length == 0) && (item.url == null || item.url.length == 0)">
							{{ open ? 'mdi-folder-open' : 'mdi-folder' }}
						</v-icon>
						
						<v-icon v-else>
							{{ files["pdf"] }}
						</v-icon>
					</template>
				</v-treeview>                
			</div>
		</div>

		<div class="pdf-view">
			<div class="viewer">
				<v-spacer />
					<vue-pdf-app 
						v-if="this.pdf.length > 0"
						style="height: 100vh;" 
						:pdf="this.pdf">
					</vue-pdf-app>
				<v-spacer />
			</div>
		</div>
	</div>
</template>

<script>
import pdf from 'vue-pdf'
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
	name: 'Documents',
	components: {
		pdf,
		VuePdfApp
	},
	props: {
	},
	data() {
		return {
			loading: false,

			results: [],
			categories:[],
			id: 0,
			pdf: "",
			search: "",

			src: undefined,
			numPages: undefined,
			open: [],

			files: {
				html: 'mdi-language-html5',
				js: 'mdi-nodejs',
				json: 'mdi-code-json',
				md: 'mdi-language-markdown',
				pdf: 'mdi-file-pdf-box',
				tml: 'mdi-file-pdf-box',
				png: 'mdi-file-image',
				txt: 'mdi-file-document-outline',
				xls: 'mdi-file-excel',
			},
		}
	},
	methods: {
		getAllDocuments() {

			this.results = []
			this.loading = true;

			axios.get(`${this.$config.api}/api.php?cmd=getAllDocuments`)
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = "* No results: Found no active documents."
						return
					}

					this.results = response.data.results
					//console.log(response.data)
					this.createCategories()
					this.loading = false
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		// Create main category for all documents
		createCategories() {
			// If the results are blank for some reason, full stop here.
			if (this.results.length <= 0) {
				console.log(" No results found to add to document tree.")
				return
			}

			this.categories = []

			for (var result of this.results){
				var indexCheck = this.categories.map(e => e.name).indexOf(result.category)

				// Do not create duplicate categories
				if (indexCheck == -1){
					this.categories.push({
						id: this.id++,
						name: result.category,
						children: []
					})
				} 
			}

			// Create all sub-categories without documents so that all sub category folders show before files in the tree.
			this.createSubCategories(true)
			// Add all documents to already created sub-categories.
			this.createSubCategories(false)
		},
		// Parse the subCategories string into sub-categories and add documents where they belong in the tree.
		createSubCategories(categoryOnly) {
			for (var result of this.results) {
				var indexCheck = this.categories.map(e => e.name).indexOf(result.category)

				// If the document has sub-categories, Add them. If not, add the document.
				if (result.subCategories != "" ){
					var subCategoryArray = result.subCategories.split("/")
					this.addSubCategory(result, subCategoryArray, 0, this.categories[indexCheck].children, categoryOnly)
				} else {
					if (!categoryOnly) {
						this.categories[indexCheck].children.push(this.addDocument(result))
					}
				}
			}
		},
		// Recursively add child sub-categories or files to the parent category.
		addSubCategory(document, subCategoryArray, subCategoryindex, workingCategory, categoryOnly){
			// Check if there is a sub-categories and add it, if not add the document instead.
			if (subCategoryArray[subCategoryindex] == null) {
				if (!categoryOnly) {
					//var previousSubCategory = workingCategory.map(e => e.name).indexOf(subCategoryArray[subCategoryindex - 1])
					workingCategory.push(this.addDocument(document))
				}
				return
			}
			
			// Check to see if the sub-category we want to add already exists in the working category.
			var checkIndex = workingCategory.map(e => e.name).indexOf(subCategoryArray[subCategoryindex])

			if (checkIndex != -1) {
				this.addSubCategory(document, subCategoryArray, subCategoryindex + 1, workingCategory[checkIndex].children, categoryOnly)
			} else {
				workingCategory.push({
					id: this.id++,
					name: subCategoryArray[subCategoryindex],
					filename: "",
					children: []
				})

				checkIndex = workingCategory.map(e => e.name).indexOf(subCategoryArray[subCategoryindex])
				this.addSubCategory(document, subCategoryArray, subCategoryindex + 1, workingCategory[checkIndex].children, categoryOnly)
			}
		},
		addDocument(document) {
			return {
				id: this.id++,
				documentID: document.id,
				name: document.name,
				description: document.description,
				filename: document.filename,
				url: document.url,
				children: []
			}
		},
		itemActive(value) {
			if (value[0] != null){
				// Load url as priority if ones is set.
				if (value[0].url.length > 0) {
					this.pdf = `https://corsproxy.io/?${encodeURIComponent(value[0].url)}`
					console.log(value[0].url)
					return
				}

				// Load an uploaded version if there is no url set.
				if (value[0].filename.length > 0) {
					this.pdf = `${this.$config.pdf}${value[0].filename}`
					console.log(`${this.$config.pdf}${value[0].filename}`)
					return
				}
			}
		},
		download() {
			window.open(this.pdf, "_blank")
		},
		onClickOutside (event) {
			this.pdf = ''
		},
	},
	computed: {
	},
	watch: {
	},
	mounted: function() {
		this.getAllDocuments()
	},	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.documents {
		display: flex;
		flex-direction: row;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.document-tree {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 600px;
		padding: 5px;
		border: 1px solid silver;
		background-color: white;
		margin: 5px;
		overflow: hidden;
	}

	.tree-container {
		height: 100%;
		width: 100%;
		overflow: auto;
		font-size: 1.5vh;
	}

	.pdf-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		height: 100%;
		width: 100%;
		padding: 5px;
		border: 1px solid silver;
		background-color: white;
		margin: 5px;
		overflow: hidden;
	}

	.loading {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 100;
	}

	.progress {
		position: relative;
		top: 40%;
	}

	.v-treeview { width: max-content; }

	#buttons {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	/* Page content */
	.content {
		padding: 16px;
	}

	.viewer {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.download {
		position: relative;
		text-align: left;
		display: block;
		width: 100%
	}
</style>